<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="d-flex justify-center">
      <div class="_temp_list_medium">
        <v-card class="rounded-lg pa-3" flat>
          <h3>Notifikasi</h3>
          <div class="my-3 d-flex align-center">
            <v-btn
              class="mr-2 text-capitalize"
              rounded
              depressed
              :color="!unRead ? '#CFE1E1' : ''"
              @click="(unRead = false), getNotif()"
            >
              <b :style="`color:${!unRead ? '#106967' : 'black'}`">
                All
              </b>
            </v-btn>
            <v-btn
              :color="unRead ? '#CFE1E1' : ''"
              class="text-capitalize"
              rounded
              @click="(unRead = true), getNotif()"
              depressed
            >
              <b :style="`color:${unRead ? '#106967' : 'black'}`">
                Unread
              </b>
            </v-btn>
            <v-spacer></v-spacer>
            <div
              @click="markAllReaded"
              class="d-flex align-center"
              style="cursor: pointer"
            >
              <v-icon color="#106967" class="mr-1">mdi-check-all</v-icon>
              <h5 class="not_bold" style="color: #106967">
                Mark all as read
              </h5>
            </div>
          </div>
          <div v-if="!unRead">
            <div v-if="!loading">
              <div v-if="notification.length && !loading">
                <v-card
                  rounded="lg"
                  flat
                  v-for="(item, i) in notification"
                  :key="i"
                  class="mb-2 item_notif bg_color1"
                  @click="toContent(item)"
                >
                  <div class="d-flex">
                    <div class="mr-2">
                      <v-icon
                        :color="!item.is_read ? '#BD9028' : 'transparent'"
                        small
                        >mdi-circle</v-icon
                      >
                    </div>
                    <div>
                      <p class="mb-1 txt_white" v-html="item.text_html"></p>
                      <p
                        :class="
                          !item.is_read == 0
                            ? 'small_txt color_default ma-0'
                            : 'small_txt  ma-0'
                        "
                      >
                        <b style="color: #bd9028">
                          . {{ $date(item.date).fromNow() }}</b
                        >
                      </p>
                    </div>
                  </div>
                </v-card>
              </div>
              <div
                class="d-flex flex-column align-center"
                v-if="!notification.length && !loading"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="lonceng"
                  viewBox="0 0 112 112"
                >
                  <rect
                    width="18.98"
                    height="18.98"
                    x="34.96"
                    y="82"
                    fill="#1876f2"
                    rx="9.49"
                    transform="rotate(-15 44.445 91.471)"
                  />
                  <circle cx="43.01" cy="26.27" r="6.85" fill="#64676b" />
                  <path
                    fill="#a4a7ab"
                    d="M75.28 43.44a26.72 26.72 0 10-51.62 13.83L30 81l51.62-13.87z"
                  />
                  <path
                    fill="#a4a7ab"
                    d="M90.78 75.64L26.33 92.9l3.22-13.63 51.62-13.83 9.61 10.2z"
                  />
                  <rect
                    width="66.91"
                    height="8.88"
                    x="25.35"
                    y="80.75"
                    fill="#a4a7ab"
                    rx="4.44"
                    transform="rotate(-15 58.793 85.207)"
                  />
                </svg>
                <h3 class="text-center">Tidak ada notifikasi</h3>
              </div>
            </div>
            <div v-if="loading">
              <v-skeleton-loader
                v-for="item in 5"
                :key="item"
                class="mb-2"
                type="list-item-avatar"
              ></v-skeleton-loader>
            </div>
          </div>
          <div v-else>
            <div v-if="!loading">
              <div v-if="notificationUnRead.length && !loading">
                <v-card
                  rounded="lg"
                  flat
                  v-for="(item, i) in notificationUnRead"
                  :key="i"
                  class="mb-2 item_notif bg_color1"
                  @click="toContent(item)"
                >
                  <div class="d-flex">
                    <div class="mr-2">
                      <v-icon
                        :color="!item.is_read ? '#BD9028' : 'transparent'"
                        small
                        >mdi-circle</v-icon
                      >
                    </div>
                    <div>
                      <p class="mb-1 txt_white" v-html="item.text_html"></p>
                      <p
                        :class="
                          !item.is_read == 0
                            ? 'small_txt color_default ma-0'
                            : 'small_txt  ma-0'
                        "
                      >
                        <b style="color: #bd9028">
                          . {{ $date(item.date).fromNow() }}</b
                        >
                      </p>
                    </div>
                  </div>
                </v-card>
              </div>
              <div
                class="d-flex flex-column align-center"
                v-if="!notificationUnRead.length && !loading"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="lonceng"
                  viewBox="0 0 112 112"
                >
                  <rect
                    width="18.98"
                    height="18.98"
                    x="34.96"
                    y="82"
                    fill="#1876f2"
                    rx="9.49"
                    transform="rotate(-15 44.445 91.471)"
                  />
                  <circle cx="43.01" cy="26.27" r="6.85" fill="#64676b" />
                  <path
                    fill="#a4a7ab"
                    d="M75.28 43.44a26.72 26.72 0 10-51.62 13.83L30 81l51.62-13.87z"
                  />
                  <path
                    fill="#a4a7ab"
                    d="M90.78 75.64L26.33 92.9l3.22-13.63 51.62-13.83 9.61 10.2z"
                  />
                  <rect
                    width="66.91"
                    height="8.88"
                    x="25.35"
                    y="80.75"
                    fill="#a4a7ab"
                    rx="4.44"
                    transform="rotate(-15 58.793 85.207)"
                  />
                </svg>
                <h3 class="text-center">Tidak ada notifikasi</h3>
              </div>
            </div>
            <div v-if="loading">
              <v-skeleton-loader
                v-for="item in 5"
                :key="item"
                class="mb-2"
                type="list-item-avatar"
              ></v-skeleton-loader>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
import {getFirestore, onSnapshot, collection, doc, orderBy, query, where, updateDoc} from "firebase/firestore";

export default {
  name: "pageNotif",
  computed: {
    ...mapState({
      notification: (state) => state.notification,
      notificationUnRead: (state) => state.notificationUnRead,
      profile: (state) => state.profile,
    }),
  },
  components: { NavbarProfile },
  data() {
    return {
      loading: false,
      unRead: false,
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Notifikasi",
          disabled: true,
          href: "#",
        },
      ],
    };
  },
  mounted() {
    this.getNotif();
  },
  methods: {
    getNotif() {
      this.loading = true;
     
      setTimeout(
        function () { 
          this.loading = false;
        }.bind(this)
      , 300);
    },
    async toContent(item) {
      this.loading = true;
      
      await updateDoc(doc(getFirestore(), "notification", item.id), {
        is_read:true,
      });
      this.$router.push(item.url_page);
      this.getNotif();
      this.loading = false;
    },
    markAllReaded() {
      this.loading = true;
      const q = query(collection(getFirestore(), "notification"), where("recipient", "==", parseInt(this.profile.data.profile.user_id)), where('is_read', '==', false), orderBy('date','desc'));
      onSnapshot(q, async(querySnapshot) => {
        querySnapshot.docs.map(async(item) => {
          await updateDoc(doc(getFirestore(), "notification", item.data().id), {
            is_read:true,
          });
        })
      });
      this.getNotif();
    },
  },
};
</script>

<style>
.temp_notif {
  overflow: hidden;
  overflow-y: scroll;
}
/* width */
.temp_notif::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.temp_notif::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
}

/* Handle */
.temp_notif::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.temp_notif::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.item_notif {
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}
.item_notif:hover {
  background: #f2f2f2;
}
.lonceng {
  width: 150px;
  height: 150px;
}
</style>
